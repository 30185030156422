import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import { useProspect } from 'hooks';
import { ScrollFooter } from 'components/UI';
import ProspectSignUpForm from './ProspectSignUpForm';
import * as Styled from './ProspectSignUp.styled';

const ProspectSignUp = ({ prospect, signUp }) => (
  <div>
    <Styled.Content>
      <h3>Stay in the loop!</h3>
      <p>Be the first to know about insider deals and Skylark exclusives!</p>
    </Styled.Content>
    <Styled.Form>
      <ProspectSignUpForm prospect={prospect} onSubmit={signUp} />
    </Styled.Form>
  </div>
);

ProspectSignUp.propTypes = {
  prospect: PropTypes.instanceOf(Immutable.Map),
  signUp: PropTypes.func,
};

const ProspectSignUpSuccess = ({ prospect }) => (
  <div>
    <Styled.Content>
      <h3>Congratulations!</h3>
      <p>{prospect.get('email')} has been subscribed to the Skylark&nbsp;mailing&nbsp;list.</p>
    </Styled.Content>
  </div>
);

ProspectSignUpSuccess.propTypes = {
  prospect: PropTypes.instanceOf(Immutable.Map),
};

const ProspectSignUpWrapper = () => {
  const [prospect, signUp, closeForm] = useProspect();
  const [showProspectSignup, setShowProspectSignup] = useState(true);

  // console.log('prospect', prospect && prospect.toJS());
  // console.log('showProspectSignup', showProspectSignup);

  useEffect(() => {
    if (showProspectSignup !== prospect.get('form_closed')) {
      setShowProspectSignup(!prospect.get('form_closed'));
    }
  }, [prospect]);

  const onFormClosed = () => {
    closeForm();
    setShowProspectSignup(false);
  };

  return (
    <ScrollFooter height={200} onClose={onFormClosed} hidden={!showProspectSignup}>
      <Styled.ProspectSignUp>
        {prospect && prospect.get('email') ? <ProspectSignUpSuccess prospect={prospect} /> : <ProspectSignUp prospect={prospect} signUp={signUp} />}
      </Styled.ProspectSignUp>
    </ScrollFooter>
  );
};

export default ProspectSignUpWrapper;
