import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Link from 'next/link';
import classNames from 'classnames';
import { Popup } from 'react-mapbox-gl';

import './index.scss';

export const ListViewPopup = ({ point, anchor, offset }) => {
  const popupCoords = point.get('longitude') ? [point.get('longitude'), point.get('latitude')] : [];
  const name = point.get('name') || point.get('title');
  const isHotel = point.get('object_type') === 'hotel';
  const classes = classNames({
    'lark-popup': true,
    'lark-popup--hotel': isHotel,
  });

  const placeTargetUrl = `/destinations/list/${point.get('access_code')}`;
  const hotelTargetUrl = `/hotels/${point.get('code')}`;
  const targetUrl = point.get('object_type') === 'hotel' ? hotelTargetUrl : placeTargetUrl;

  return Popup && popupCoords.length ? (
    <Popup anchor={anchor} coordinates={popupCoords} offset={{ bottom: [0, 0 - offset] }}>
      <Link href={targetUrl} as={targetUrl}>
        <div className={classes} style={{ marginBottom: 5 }}>
          {name ? <p className="lark-popup__name">{name}</p> : null}
        </div>
      </Link>
    </Popup>
  ) : null;
};

ListViewPopup.defaultProps = {
  anchor: 'bottom',
  point: Immutable.Map(),
  offset: 0,
};

ListViewPopup.propTypes = {
  anchor: PropTypes.string,
  point: PropTypes.instanceOf(Immutable.Map),
  offset: PropTypes.number,
};

export default ListViewPopup;
