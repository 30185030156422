import React, { useEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import * as Input from 'components/Forms/Input';

import { Button } from 'components/UI';

const prospectSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup
    .string()
    .required('Required')
    .email('Invalid email'),
});

const defaultAttribute = (objImmutable, attr) => (objImmutable ? objImmutable.get(attr) : undefined);

const ProspectSignUpForm = ({ prospect, onSubmit }) => {
  // console.log('ProspectSignUpForm > prospect', prospect && prospect.toJS());
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(prospectSchema),
    defaultValues: {
      firstName: defaultAttribute(prospect, 'first_name'),
      lastName: defaultAttribute(prospect, 'last_name'),
      email: defaultAttribute(prospect, 'email'),
    },
  });

  useEffect(() => {
    if (prospect && prospect.get('errors')) {
      setError('email', { type: 'manual', message: prospect.getIn(['errors', 0]) });
    }
  }, [prospect]);

  const onFormSubmit = (data) => {
    const { firstName, lastName, email } = data;
    onSubmit(firstName, lastName, email);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        {/* <Input.Text
          // label="Name"
          placeholder="First Name"
          autoComplete="given-name"
          flex={2}
          type="text"
          error={errors?.firstName}
          name="firstName"
          register={register}
          errorPosition="bottom"
        /> */}
        {/* <Input.Text
          // label="Name"
          placeholder="Last"
          autoComplete="family-name"
          flex={2}
          type="text"
          error={errors?.lastName}
          name="lastName"
          register={register}
        /> */}

        <Input.Text
          // label="Email Address"
          placeholder="Email Address"
          autoComplete="email"
          flex={2}
          type="text"
          error={errors?.email}
          name="email"
          register={register}
          errorPosition="bottom"
        />

        <Button type="submit" className="button-form" context="Submit">
          Join
        </Button>
      </form>
    </>
  );
};

ProspectSignUpForm.propTypes = {
  prospect: PropTypes.instanceOf(Immutable.Map),
  onSubmit: PropTypes.func,
};

export default ProspectSignUpForm;
