import styled from 'styled-components';
import { variables, colors, toRgba } from 'styles';

export const ProspectSignUp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.modal {
    padding: 30px !important;
    background: ${colors.white};
  }

  & > div {
    max-width: 600px;
    @media (max-width: ${variables.screen.xs}) {
      margin: 1rem;
    }
  }
`;

export const Content = styled.div`
  h3 {
    font-size: 1.5rem;
  }
`;

export const Form = styled.div`
  form {
    display: flex;
  }

  button[type='submit'] {
    height: 36px;
    margin: 1.5px !important;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid ${colors.coolGray};
  }
`;
